import React, { useEffect, useState } from 'react'
import {hiring,HiringRequirements} from '../api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HiringRequirement from './HiringRequirement';
const Hiring = () => {
    const [hiringrequirements,setHiringrequirements] = useState([]);
    useEffect(()=>{
        const hirecallfunction=async() => {
            const {data} =await HiringRequirements()
            setHiringrequirements(data.HiringRequirementsData)
        }
        hirecallfunction()
    },[])

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [stream, setStream] = useState("");
    const [streamother, setStreamother] = useState("");
    const [selectedfile, setSelectedfile] = useState(null);
    const [yourmessage, setYourmessage] = useState("");
  
  function selectStream() {
    const stream = document.getElementById('streamSelect').value
    setStream(stream);
    // console.log(stream)
    if (stream == 'Other') {
        document.getElementById('other').classList.remove('stream')
        document.querySelector('#otherInput').setAttribute('required', 'true')
    } else {
        document.getElementById('other').classList.add('stream')
        document.querySelector('#otherInput').removeAttribute('required', 'true')
    }
}
//selectedfile
  const handleFileSelect = (event) => {
    const blobUrl = URL.createObjectURL(event.target.files[0])
    setSelectedfile(blobUrl)
  }
    const handleSubmit = async (event) => {
        event.preventDefault();
        let data =  new FormData();
        // console.log("name",name,"::email",email,"::phone",phone,"::details",yourmessage,"::select",stream,"::streamother",streamother,":::selectedfile",selectedfile)
        data.append("name", name);
        data.append("email", email);
        // console.log("formdata",formData);
        data.append("phone", phone);
        data.append("details", yourmessage);
        data.append("select", stream);
        data.append("image", selectedfile);
        data.append("streamother", streamother);
        try {
        //   console.log("all data hiring",data);
          let res = await hiring(data)
        //   console.log("response:::::",res)
          if (res.status === 200) {
            setName("");
            setEmail("");
            setPhone("");
            setYourmessage("");
            toast("Your Details Submit Successfully!");
          } else {
            console.log(res)
          }
        } catch (err) {
          console.log(err);
        }
      };
  return (
    <>
    <section className="m-100" id="hire">
    <div className="wesite-anim">
        <img draggable="false" src="/Assets/images/website-anim.png" alt="website-anim" className="img-fluid" />
    </div>
    <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
        <i className="fad fa-horizontal-rule"></i> We are Hiring <i className="fad fa-horizontal-rule"></i>
    </h1>
    <h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3 ">Tell Us About Your Skills </h4>
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <form onSubmit={handleSubmit} class="p-4" encType="multipart/form-data">
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" className="form-control"  value={name} onChange={(e) => setName(e.target.value)} name="name" id="name" placeholder="Enter Your Name"
                            required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email Address</label>
                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Enter Your Email Address"
                            required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Phone No</label>
                        <input type="number" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} name="phone" placeholder="Enter Your Contact No"
                            required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Enter Details</label>
                        <textarea className="form-control" name="details" value={yourmessage} onChange={(e) => setYourmessage(e.target.value)} placeholder="Leave a Details Here"
                            style={{height: "100px"}} required></textarea>
                    </div>
                    <div className="my-3">
                        <label htmlFor="resume" className="form-label">Upload Your Resume</label>
                        <input type="file" className="form-control" onChange={e => handleFileSelect(e)} name="file" id="resume" required accept=".jpg,.png,.pdf,.docx" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="resume" className="form-label">Select Stream</label>
                        <select name="select"  onChange={() => selectStream()} className="form-select" id="streamSelect" required>
                            <option value="Android Developer">Android Developer</option>
                            <option value="Web Developer">Web Developer</option>
                            <option value="IOS Developer">IOS Developer</option>
                            <option value="Graphic Designer">Graphic Designer</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="mb-3 stream" id="other">
                        <label className="form-lable">Enter Your Stream</label>
                        <input type="text" className="form-control" onChange={(e) => setStreamother(e.target.value)}  id="otherInput" name="stream_other" placeholder="Enter Stream Here" />
                    </div>
                    <button type="submit" className="btn btn-lg my-btn w-100 service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Submit</button>
                </form>
            </div>
            <div className="col-lg-6">
                {/* <img src="/Assets/images/hire.png" className="img-fluid p-4 hire-img" alt="" /> */}
                <div className="accordion accordion-flush p-4" id="accordionFlushExample">
                {hiringrequirements.map((singledata, indexno) => {
                    return <HiringRequirement data={singledata}  dataindex={indexno}  sethiringrequirements={setHiringrequirements}/>
                })}
      
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default Hiring