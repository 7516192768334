import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
     <footer>
        <img draggable="false" src="/Assets/images/footer-design.png" alt="footer"
            className="img-fluid footer-design" />
        <div className="footer-bubble bubble-1"></div>
        <div className="footer-bubble bubble-2"></div>
        <div className="footer-bubble bubble-3"></div>
        <div className="footer-bubble bubble-4"></div>
        <div className="footer-anim"><i className="fas fa-times"></i></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <div className=" footer-logo">
                        <img draggable="false" src="/Assets/images/footerlogo.png" alt="footerlogo" height="55px"
                            className="img-fluid" />
                    </div>
                    <h4 className="fw-bold text-white mt-4">Follow Us on</h4>
                    <ul className="text-white m-0 mt-3 p-0 media d-flex flex-wrap" style={{listStyle: "none"}}>
                        <li style={{width: "50%"}}>
                            <a href="https://www.facebook.com/yamiitsolutions?mibextid=ZbWKwL" className="text-white h6"><i
                                    className="fab fa-facebook-square me-2 my-2"></i>Facebook</a>
                        </li>
                        <li style={{width: "50%"}}>
                            <a href="https://instagram.com/yami_it_solutions?igshid=YmMyMTA2M2Y="
                                className="text-white h6"><i className="fab fa-instagram me-2 my-2"></i>Instagram</a>
                        </li>
                        <li style={{width: "50%"}}>
                            <a href="https://www.instagram.com/yami_it_solutions?igsh=Nzd6cjh1OWgzZzU4" className="text-white h6"><i className="fab fa-linkedin me-2 my-2"></i>Linkedin</a>
                        </li>
                        <li style={{width: "50%"}}>
                            <a href="#" className="text-white h6"><i className="fab fa-twitter-square me-2 my-2"></i>Twitter</a>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-4">
                    <h3 className="text-white fw-bold f-title my-3 mb-4"><i className="fab fa-servicestack me-2"></i>Services
                    </h3>
                    <ul className="text-white" style={{lineHeight: "0.5 !important"}}>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>Website Development</h5>
                            </a></li>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>Software Development
                                </h5>
                            </a></li>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>E-Commerce Solutions</h5>
                            </a></li>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>Health Care Solutions</h5>
                            </a></li>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>ERP Systems</h5>
                            </a></li>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>CRM Systems</h5>
                            </a></li>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>Logistics Solutions </h5>
                            </a></li>
                        <li className="my-3"><a href="#services" className="text-white">
                                <h5 className="lh-base"><i className="far fa-angle-double-right me-2"></i>Mobile Apps </h5>
                            </a></li>
                    </ul>
                </div>
                <div className="col-lg-4" style={{transform:"translateY(15px)"}}>
                    <h3 className="text-white fw-bold f-title my-3 mb-4"><i className="fas fa-id-card me-2"></i>Contact Info
                    </h3>
                    <ul className="text-white">
                        <li className="my-3">
                            <h5><i className="fas fa-address-book me-2 lh-lg"></i>+91 261-356-4802 &nbsp;| +91 9925866146</h5>
                        </li>
                        <li className="my-3">
                            <h5><i className="fas fa-envelope me-2 lh-lg"></i>admin@yamiitsolutions.com</h5>
                        </li>
                        <li className="my-3">
                            <h5><i className="fas fa-map-marker-alt me-2 lh-lg"></i> 322, Rise On plaza, Above ICICI Bank,
                                Sarthana Jakatnaka, Surat, Gujarat - 395006</h5>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className="bg-white"/>
            <h6 className="text-center text-white">Copyright © 2024 Yami It Solutions. All Rights Reserved.</h6>
        </div>
    </footer>
    </>
  )
}

export default Footer