import React, { useEffect, useState } from 'react'
import {galleryvideo , gallery} from '../api'
import GalleryVideo from './GalleryVideo';
import OwlCarousel from 'react-owl-carousel';   
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Gallery = () => {

    let imgPath = document.getElementById('modal-img')
    let curruntImg
    var imgArr
    let imgGallary = async (e) => {
        imgArr = [];
        console.log(e.target.src);
        imgPath.src = e.target.src;
        console.log()
        const gallaryImg = document.getElementsByClassName('gallaryImg');
        for (let i = 0; i < gallaryImg.length; i++) {
            const img = gallaryImg[i];
            imgArr.push(img.src);
        } 
        curruntImg = imgArr.indexOf(imgPath.src);
    }
    function prev() {
        if (curruntImg > 0){
            curruntImg = curruntImg-1;
            imgPath.src = imgArr[curruntImg];
        }
    }
    function next() {
        if (curruntImg < imgArr.length-1){
            curruntImg = curruntImg + 1;
            console.log(curruntImg);
            imgPath.src = imgArr[curruntImg];
        }
    }
    const [allgalleryvideo,setAllgalleryvideo] = useState([]);
    const [allgallery,setAllgallery] = useState([]);
    useEffect(()=>
    {
        const callfunction=async()=>{
            const {data} =await galleryvideo()
            setAllgalleryvideo(data.GalleryVideoData)
        }
        callfunction()
    },[])
    useEffect(()=>
    {
        const callfunction=async()=>{
            const {data} =await gallery()
            setAllgallery(data.GalleryData)
        }
        callfunction()
    },[])
    // var playerSettings = {
    //     controls : ['play-large'],
    //     fullscreen : { enabled: false},
    //     resetOnEnd : true,
    //     hideControls  :true,
    //     clickToPlay:true,
    //     keyboard : false,
    //   }
//   const players = Plyr.setup('.js-player', playerSettings);
//        players.forEach(function(instance,index) {
//               instance.on('play',function(){
//                   players.forEach(function(instance1,index1){
//                     if(instance != instance1){
//                           instance1.pause();
//                       }
//                   });
//               });
//           });
//        $('.video-section').on('translated.owl.carousel', function (event) {
//        players.forEach(function(instance,index1){
//                       instance.pause();
//                       });
//        });
     
  return (
    <>
<section>
    <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
        <i className="fad fa-horizontal-rule"></i> Gallery <i className="fad fa-horizontal-rule"></i>
    </h1>
    <h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3">Our Great Moments</h4>
    <div className="gallary-section">
        <div className="container">
            <div className=" gallary">
                <div id="gallery" className="container-fluid">
                    {allgallery.map(({Image}, index) => {
                       return <img src={`https://admin.doubleplusinfotech.com/public${Image}`} data-bs-toggle="modal" data-bs-target="#myModal"
                        className="img-responsive gallaryImg" onClick={(e) =>imgGallary(e)} />
                    })}
                </div>
                <div className="modal fade" id="myModal" tabindex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content bg-transparent border-0 d-flex align-items-center flex-row">
                            <div>
                                <button className="btn my-btn btn-lg bg-prime rounded-pill me-5" onClick={prev}><i className="text-white fas fa-chevron-left"></i></button>
                            </div>
                            <div className="modal-body">
                                <img className="img-responsive modal-img zoom" alt="img" id="modal-img" />
                            </div>
                            <div>
                            <button className="btn my-btn bg-prime btn-lg rounded-pill ms-5" onClick={next}><i
                                        className="text-white fas fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
            <i className="fad fa-horizontal-rule"></i> Videos <i className="fad fa-horizontal-rule"></i>
        </h1>
        <h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3 ">Some Memorable Highlights  </h4>
        <div className="video-section">
        <OwlCarousel  autoplay= {true} items={3}>
                {allgalleryvideo.map((singledata, index) => {
                    return <GalleryVideo data={singledata} key={index}  setAllgalleryvideo={setAllgalleryvideo}/>
                })}
        </OwlCarousel>
      </div> */}
</section>
    </>
  )
}
export default Gallery